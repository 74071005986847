/* eslint-disable linebreak-style */
import React from 'react';

// export const lightThemes = new Set(['Blue', 'Rhubarb and Custard','Space Grey','Red Dead', 'Matrix', 'FallGuys']);
// export const darkThemes = new Set(['Midnight Dreams','Midnight Green', 'Apocalypse', 'Twitter', 'Christmas']);
// const allThemes = [...lightThemes, ...darkThemes];

const allThemes = [
  {
    id: "theme-blue",
    name: "I'm Blue"
},
// {
//     id: "theme-rhubarbandcustard",
//     name: "Twilight Zone"
// },
//  {
//    id: "theme-matrix",
//    name: "Matrix",
//  },
//  {
//    id: "theme-pink",
//    name: "Pink",
//  },
// {
//     id: "theme-midnightdreams",
//     name: "Midnight Dreams",
// },
//  {
//    id: "theme-apocalypse",
//    name: "Apocalypse",
//  },
//   {
//       id: "theme-midnightgreen",
//       name: "Midnight Green",
//   },
  // {
  //     id: "theme-spacegray",
  //     name: "Chance of Rain"
  // },
  {
      id: "theme-twitter",
      name: "Gone Dark"
  },
//  {
//      id: "theme-reddead",
//      name: "Red Dead",
//  },
//  {
//      id: "theme-fallguys",
//      name: "Fall Guys",
//  },
//  {
//      id: "theme-christmas",
//      name: "Christmas",
//  },
//  {
//    id: "default-theme",
//    name: "default",
// },
{
  id: "theme-origdefault",
  name: "Lights On"
}
];



const ThemePicker = ({ theme, setTheme }) => {
  if (theme) {
    return (
      <div>
        {allThemes.map((item, index) => {
          const nextTheme =
          allThemes.length - 1 === index
            ? allThemes[0].id
            : allThemes[index + 1].id;

          return item.id === theme ? (
            <div key={item.id} className={item.id}>
              <button
                type="button"
                aria-label={`Theme ${item.name}`}
                onClick={() => setTheme(nextTheme)}
              >
                {item.name}
              </button>
            </div>
          ) : null;
        })}
      </div>
    );
  }
  return null;
};

export default ThemePicker;
