import React, { useContext, useState } from 'react';
import { ThemeContext } from 'gatsby-plugin-theme-switcher';
import { graphql, Link, useStaticQuery } from 'gatsby';
import ThemeSwitcher from './ThemeSwitcher';
import Menu from './Menu';
import Hamburger from './Hamburger';

// import logo from '../../static/images/logo/logo.svg';
// import logoMobile from '../../static/images/logo/logo-mobile.svg';
// import MenuMobile from './MenuMobile';

const Header = ({ isLink }) => {
  const { theme, switchTheme } = useContext(ThemeContext);
  const [menuActive, setMenuActive] = useState(false);

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      configJson {
        logo {
          alt
          desktop_letters_inline
          mobile_letters_inline
          footer_height
        }
      }
    }
  `);

  const toggleMenuStatus = (menuActive) => {
    setMenuActive(prevState => ({
      menuActive: !prevState.menuActive
    })  );
  };

  return (
    <div className="header">
      <div className="container">
        <div className="logo">
          <Link to="/">
            <img
              height={data.configJson.logo.footer_height}
              alt={data.configJson.logo.alt}
              src={data.configJson.logo.desktop_letters_inline}
            />
          </Link>
        </div>
        <div className="logo-mobile">
          <Link to="/">
            <img
              height={data.configJson.logo.footer_height}
              alt={data.configJson.logo.alt}
              src={data.configJson.logo.mobile_letters_inline}
            />
          </Link>
        </div>
        <div className="middlebutton">
          <ThemeSwitcher theme={theme} setTheme={switchTheme} />
        </div>
        {/* <MenuMobile active={menuActive} /> */}
        <Menu />
        <Hamburger toggleMenu={toggleMenuStatus} />
      </div>
    </div>
  );
};

export default Header;
