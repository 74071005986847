import React, { useContext } from 'react';
import { ThemeContext } from 'gatsby-plugin-theme-switcher';
import { graphql, useStaticQuery, Link } from 'gatsby';
import ThemeSwitcher from './ThemeSwitcher';
// import Social from './Social';


const Footer = props => {
  const { theme, switchTheme } = useContext(ThemeContext);
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allFooterMenuJson {
        edges {
          node {
            weight
            url
            name
          }
        }
      }
      configJson {
        logo {
          alt
          desktop_letters_inline
          mobile_letters_inline
          footer_height
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="footer-inner">
              {/* <h3 className="footer-title">{data.site.siteMetadata.title}</h3> */}
              <div className="logo">
                <Link to="/">
                  <img
                    height={data.configJson.logo.footer_height}
                    alt={data.configJson.logo.alt}
                    src={data.configJson.logo.desktop_letters_inline}
                  />
                </Link>
              </div>
              <div className="logo-mobile">
                <Link to="/">
                  <img
                    height={data.configJson.logo.footer_height}
                    alt={data.configJson.logo.alt}
                    src={data.configJson.logo.mobile_letters_inline}
                  />
                </Link>
              </div>
              {/* <Social /> */}
              <ThemeSwitcher theme={theme} setTheme={switchTheme} />
              <ul>
                {data.allFooterMenuJson.edges.map(({ node }) => (
                  <li key={node.name}>
                    <Link to={node.url}>{node.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;