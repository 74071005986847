import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import favicon from '../../static/favicon-32x32.svg';

const SEO = props => (
  <StaticQuery
    query={detailsQuery}
    render={data => {
      const title = props.title || data.site.siteMetadata.title;
      return (
        <Helmet
          htmlAttributes={{
            lang: 'en'
          }}
          title={title}
          titleTemplate={props.title ? `%s` : `%s - ${data.site.siteMetadata.title}`}
          link={[
            { rel: 'shortcut icon', type: 'image/svg', href: `${favicon}` }
          ]}
        >
          /* new stuff from RVA2 queries  */
          <meta name='description' content={data.site.siteMetadata.description} />, 
          <meta
            name='keywords'
            content='toy vending, bouncy ball, photobooh, photo booth, fun photos, gacha, gashapon, bandai, lollipop, gumball, tomy'
          />
          <meta name='image' content={data.site.siteMetadata.image} />
          <meta name='author' content={data.site.siteMetadata.author} />
          <meta name='twitter:card' content='summary_large_image' />
          <meta name='twitter:creator' content={data.site.siteMetadata.twitterUsername} />
          <meta name='twitter:title' content={data.site.siteMetadata.Title} />
          <meta name='twitter:description' content={data.site.siteMetadata.description} />
          <meta name='twitter:image' content={`${data.site.siteMetadata.siteUrl}${data.site.siteMetadata.image}`} />
          <meta name='twitter:url' content={data.site.siteMetadata.siteUrl} />
    
          <meta
            name='robots'
            content='follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
          />
          <link rel='canonical' href={data.site.siteMetadata.siteUrl} />
          <meta property='og:updated_time' content='2022-08-22T21:00:00+10:00' />
    
          <meta property='og:locale' content='en_US' />
          <meta property='og:site_name' content={data.site.siteMetadata.siteTitle} />
          <meta property='og:url' content={data.site.siteMetadata.siteUrl} />
          <meta property='og:type' content='website' />
          <meta property='og:title' content={data.site.siteMetadata.siteTitle} />
          <meta property='og:description' content={data.site.siteMetadata.siteDesc} />
          <meta property='og:image' content={`${data.site.siteMetadata.siteUrl}${data.site.siteMetadata.image}`} />
          <meta property='og:image:secure_url' content={`${data.site.siteMetadata.siteUrl}${data.site.siteMetadata.image}`} />
          <meta property='og:image:width' content='400' />
          <meta property='og:image:height' content='300' />
          <meta property='og:image:alt' content='UnralToys' />
          <meta property='og:image:type' content='image/png' />
    
          <meta property='ya:ovs:upload_date' content='2022-08-22' />
          <meta property='ya:ovs:allow_embed' content='true' />
        </Helmet>
      );
    }}
  />
);

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: 'toy vending, bouncy ball, photobooh, photo booth, fun photos, gacha, gashapon, bandai, lollipop, gumball, tomy'
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
        image
        twitterUsername
      }
    }
  }
`;
